<template>
  <div class="w-work-center-wrap">
    <div class="container">
      <div class="search-wrap">
        <div class="serch">
          <el-input
            placeholder="请输入工作组名称或成员"
            class="w-input-sty store-input input-solid"
            v-model="searchVal"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <span @click="search()">查 询</span>
        </div>
      </div>

      <div class="table-warp">
        <el-table
          class="tbs-black tbs-wrap is-scroll-x tbs-th-light"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column prop="workTeamId" label="序号" width="180"></el-table-column> -->
          <el-table-column prop="workTeamName" label="工作组名称"></el-table-column>
          <el-table-column label="工作组成员">
            <template slot-scope="scope">
              <div>
                <span v-for="(item,index) in scope.row.workTeamMembers" :key="index">
                  {{ item.memberName }}
                  <span
                    v-show="index + 1 != scope.row.workTeamMembers.length"
                  >,</span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="btns">
                <span @click="eiditTeam(scope.row)">编辑</span>
                <span @click="delTeam(scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="w-page-sty">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="pageNum"
            @current-change="changeCurrent"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-drawer
      class="black-drawer"
      :visible.sync="drawer"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
      @close="handleClose"
      :wrapperClosable="true"
      :destroy-on-close="true"
      size="560px"
    >
      <div class="drawer-header">
        <div class="header-title">
          <div>工作组管理</div>
          <div>
            <i class="el-icon-close" @click="drawer = false"></i>
          </div>
        </div>
      </div>
      <div class="w-input-sty drawer-warp">
        <el-form :model="dataPost" :rules="rules" label-width="100px" ref="dataPost">
          <div class="item-title">基础信息</div>
          <el-form-item label="工作组名称" prop="workTeamName">
            <el-input
              class="input-solid seach-input"
              placeholder="请输入工作组名称"
              v-model="dataPost.workTeamName"
              style="width:260px"
              maxlength="8"
            ></el-input>
          </el-form-item>

          <div class="item-title">成员管理</div>
          <el-form-item label="工作组成员" prop>
            <div class="team-person" style="width:260px" @click="setTeam(dataPost.workTeamMembers)">
              <div>
                <span v-for="(item,index) in dataPost.workTeamMembers" :key="index">
                  {{ item.memberName || item.name }}
                  <span
                    v-if="index !== dataPost.workTeamMembers.length - 1"
                  >,</span>
                </span>
              </div>
              <div>
                <i class="icon-work-menu"></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="drawer__footer">
        <span @click="confirmBtn">确 定</span>
        <span @click="handleClose">取 消</span>
      </div>
    </el-drawer>
    <SetDialog
      :selfData="dialogData"
      @comfirm="dialogConfirm"
      @closeDialog="closeDialog"
      v-if="dialogFlag"
      :tabKey="1"
      ref="dialog"
    />
  </div>
</template>
<script>
import api from '@/api'
import SetDialog from '@/components/setDialog.vue'
import { powerAuth } from '@/utils'
export default {
  name: 'teamManagement',
  components: { SetDialog },
  data () {
    return {
      dialogFlag: false,
      tabKey: 0,
      dialogData: [],
      total: 0,
      pages: 0,
      pageSize: 10,
      pageNum: 0,
      searchVal: '',
      drawer: false,
      rules: {
        workTeamName: [
          { required: true, message: '请输入工作组名称', trigger: 'blur' },
          { max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ]
      },
      direction: 'rtl',
      tableData: [],
      dataPost: {
        operationType: 0,
        state: 0,
        workTeamId: 0,
        workTeamMembers: [],
        workTeamName: ''
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialogFlag = false
    },
    showCreatForm () {
      if (!powerAuth(234, 'add')) {
        this.hasnotPermission()
        return false
      }
      this.dataPost.operationType = 0
      this.drawer = true
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    dialogConfirm (e) {
      // this.dialogFlag = false
      this.dataPost.workTeamMembers = e
    },
    changeCurrent (val) {
      this.pageNum = val
      this.getTeamList()
    },
    confirmBtn () {
      // 做一下校验 没输入名字
      this.$refs.dataPost.validate((valid) => {
        if (valid) {
          this.axios.post(api.saveOrUpdateWorkTeam, this.dataPost)
            .then((res) => {
              console.log(res)
              if (res.data.code === 0) {
                this.drawer = false
                this.getTeamList()
                if (this.dataPost.operationType === 0) {
                  this.$message.success('新增成功!')
                } else {
                  this.$message.success('修改成功！')
                }
              }
            })
        }
      })
    },

    getTeamList () {
      this.axios.get(api.listWorkTeam, { params: { filterContent: this.searchVal, pageNum: this.pageNum, pageSize: 10 } })
        .then((res) => {
          this.tableData = res.data.data.dataList
          this.total = res.data.data.total
          this.pages = res.data.data.pages
          this.pageNum = res.data.data.pageNum
          this.pageSize = res.data.data.pageSize
        })
    },
    handleClose () { // 关闭弹窗之前 重置提交数据
      console.log('关闭前回调')
      this.dataPost = this.$options.data().dataPost
      this.drawer = false
    },
    search () {
      this.pageNum = 1
      this.getTeamList()
    },
    handleClick (row) {
      console.log(row)
    },
    setTeam (e) {
      if (e) {
        this.dialogData = e.map(item => {
          item.name = item.name ? item.name : item.memberName
          item.id = item.member
          return item
        })
      }
      this.dialogFlag = true
      this.$nextTick(() => {
        this.$refs.dialog.open()
      })
    },
    delTeam (e) {
      if (!powerAuth(234, 'del')) {
        this.hasnotPermission()
        return false
      }
      this.dataPost.workTeamId = e.workTeamId
      this.$confirm('此操作将删除该工作组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataPost.operationType = 2
        this.axios.post(api.saveOrUpdateWorkTeam, this.dataPost)
          .then((res) => {
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.pageNum = 1
              this.getTeamList()
            }
          })
      })
    },
    eiditTeam (e) {
      if (!powerAuth(234, 'redact')) {
        this.hasnotPermission()
        return false
      }
      this.dataPost.operationType = 1
      this.dataPost.workTeamId = e.workTeamId
      this.dataPost.workTeamName = e.workTeamName
      this.dataPost.workTeamMembers = e.workTeamMembers
      this.drawer = true
    }
  },
  mounted () {
    this.getTeamList()
  }
}
</script>
<style lang="stylus" scoped>
.w-work-center-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .container
    width 100%
    height 100%
    background-color #272930
    color #fff
    padding-bottom 100px
    .search-wrap
      padding 0 32px
      display flex
      justify-content space-between
      align-items center
      height 88px
      background #272930
      box-sizing border-box
      .serch
        display flex
        .w-input-sty
          width 320px
          height 40px
          background #373A43
          border-radius 2px
          & >>> .el-input__inner
            width 320px
            height 40px !important
        span
          margin-left 16px
          width 80px
          height 40px
          background #FFA134
          border-radius 2px
          text-align center
          line-height 40px
          font-size 16px
          font-weight 400
          color #fff
          cursor pointer
      .project-select
        & >>> .el-input__inner
          width 240px !important
          height 40px !important
    .table-warp
      padding 0 32px
      .btns
        color #F89407
        span
          display inline-block
          cursor pointer
          &:nth-child(2)
            margin-left 10px
  .black-drawer>>>
    .el-input__count-inner
      background #373a43
    .drawer-warp
      padding 24px
    .input-solid
      .el-input__inner
        width 100%
    .drawer-header
      .header-title
        display flex
        justify-content space-between
        color #fff
    .drawer-warp
      .item-title
        color #fff
        position relative
        &:before
          content ''
          display block
          position absolute
          left -9px
          top 3px
          height 19px
          width 2px
          background #f89407
      .el-form-item
        margin-top 10px
      .team-person
        background #373A43
        line-height 36px
        height 36px
        display flex
        cursor pointer
        div
          &:nth-child(1)
            width 260px
            color #fff
            text-overflow ellipsis
            -o-text-overflow ellipsis
            text-overflow ellipsis
            white-space nowrap
            -webkit-line-clamp 1
            overflow hidden
            padding 0 4px 0
            position relative
          &:nth-child(2)
            text-align center
            line-height 36px
            width 39px
    .drawer__footer
      position absolute
      width 100%
      bottom 0
      padding 20px 0
      display flex
      justify-content center
      border-top 1px solid #373A43
      span
        color #fff
        cursor pointer
        font-size 14px
        &:nth-child(1)
          width 130px
          height 36px
          line-height 36px
          text-align center
          background #F89407
          opacity 1
          border-radius 4px
        &:nth-child(2)
          width 130px
          height 36px
          line-height 36px
          text-align center
          background #373A43
          opacity 1
          border-radius 4px
          margin-left 20px
</style>
